import requests from "./httpService";

const UserServices = {
  
  userLogin: async (body) => {
    return requests.post(`/users/login`, body);
  },
 
  userSignup: async (body) => {
    return requests.post(`/users/register`, body);
  },

  userContactUs: async (body) => {
    return requests.post(`/contactUs/postContactUsForm`, body);
  },
  
};

export default UserServices;
