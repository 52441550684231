import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import LabelArea from "../components/LabelArea";
import { Form } from "react-bootstrap";
import { notifyError,notifySuccess } from "../utils/toast";
import UserServices from "../services/UserServices";
export const ContactUs = () => {
    const [errors, setErrors] = useState({});

    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const validateForm = () => {
        let valid = true;
        let newErrors = {};
        const regex = /[0-9]/;
        const regexEmail = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const hasSpace = (str) => typeof str === "string" && str.trim() !== str;


        if (!data.name) {
            newErrors = {
                ...newErrors,
                name: "Name is required",
            };
            valid = false;
        } else if (hasSpace(data.name)) {
            newErrors = {
                ...newErrors,
                name: "Name should not start or end with a space",
            };
            valid = false;
        }

        if (!data.phone) {
            newErrors = {
                ...newErrors,
                phone: "Phone Number is required",
            };
            valid = false;
        } else if (hasSpace(data.phone)) {
            newErrors = {
                ...newErrors,
                phone: "Phone Number should not start or end with a space",
            };
            valid = false;
        } else if (!regex.test(data.phone)) {
            newErrors = {
                ...newErrors,
                phone: "Please Enter Number only",
            };
            valid = false;
        } else if (data.phone.length !== 10) {
            newErrors = {
                ...newErrors,
                phone: "Please Enter Valid Number",
            };
            valid = false;
        }

        if (!data.email) {
            newErrors = {
                ...newErrors,
                email: "Email is required",
            };
            valid = false;
        } else if (hasSpace(data.email)) {
            newErrors = {
                ...newErrors,
                email: "Email should not start or end with a space",
            };
            valid = false;
        } else if (!regexEmail.test(data.email)) {
            newErrors = {
                ...newErrors,
                email: "Please Enter Valid Email only",
            };
            valid = false;
        }

        if (!data.message) {
            newErrors = {
                ...newErrors,
                message: "Message is required",
            };
            valid = false;
        }

        console.log(newErrors);
        setErrors(newErrors);
        return valid;
    };

    const handleSubmit =  async(e) => {
        console.log(data);

        e.preventDefault();
        // useSplashSubmit()
        if (!validateForm()) {
            return;
        }
        try {

            await UserServices.userContactUs(data);
            notifySuccess("Query Submit");
            setData({
                name: "",
                email: "",
                phone: "",
                message: ""
            })


        } catch (error) {
            console.error("Error submitting form:", error);
            notifyError(error?.response?.data?.message || error?.message);
        }
    };

    return (
        <>
            <section className="get-in-touch pt-5 mt-5">
                <Container>
                    <Row>
                        <Col md={8} className="pq-form-box">
                            <div className="pq-section pq-style-1 text-center p-0 mb-5">
                                <span className="pq-section-sub-title">contact us</span>
                                <h5>Get in touch with us</h5>
                            </div>
                            <div className="pq-applyform-whitebg text-start">
                                <form className="inner-form"  onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={6}>
                                            <LabelArea label="Name" />
                                            <input
                                                id="name"
                                                name="name"
                                                value={data.name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.name && (
                                                <span className="text-red-400 text-sm mt-2">
                                                    {errors.name}
                                                </span>
                                            )}
                                        </Col>
                                        <Col md={6}>
                                            <LabelArea label="Mobile No" />
                                            <input
                                                id="phone"
                                                name="phone"
                                                value={data.phone}
                                                onChange={handleInputChange}
                                            />
                                            {errors.phone && (
                                                <span className="text-red-400 text-sm mt-2">
                                                    {errors.phone}
                                                </span>
                                            )}
                                        </Col>
                                        <Col md={12}>
                                            <LabelArea label="Email Address" />
                                            <input
                                                id="email"
                                                name="email"
                                                value={data.email}
                                                onChange={handleInputChange}
                                            />
                                            {errors.email && (
                                                <span className="text-red-400 text-sm mt-2">
                                                    {errors.email}
                                                </span>
                                            )}
                                        </Col>
                                        <Col md={12}>
                                        <LabelArea label="Comments" />

                                            <textarea id="message"
                                                name="message"
                                                value={data.message}
                                                onChange={handleInputChange} cols="2" rows="2" placeholder="Write Your Message"></textarea>
                                                {errors.message && (
                                                <span className="text-red-400 text-sm mt-2">
                                                    {errors.message}
                                                </span>
                                            )}
                                        </Col>




                                        <Col md={12}>
                                            <button variant="primary" className="form-btn">Submit</button>
                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    );
};
